import * as React from 'react';
import {
  createStyles,
  Grid,
  Typography,
  WithStyles,
  withStyles,
  Button,
  Theme
} from '@material-ui/core';
import ExternalLayoutComponent from '../../components/ExternalLandingpageLayout.component';
import iconSuccess from '../../assets/images/iconSuccess.svg';

const styles = (theme: Theme) =>
  createStyles({
    '@keyframes fadein': {
      '0%': {
        opacity: 0,
        transform: 'translate3d(-50%, 0, 0)'
      },
      '100%': {
        opacity: 1,
        transform: 'translate3d(0, 0, 0)'
      }
    },

    '@keyframes ease': {
      '0%': {
        opacity: 0,
        transform: 'translate3d(0, -50%, 0)'
      },
      '100%': {
        opacity: 1,
        transform: 'translate3d(0, 0, 0)'
      }
    },

    tetxUnited: {
      color: '#00AFC1',
      fontWeight: 600,
      animation: `$fadein 2s ease-in both`,
      [theme.breakpoints.down('sm')]: {
        fontSize: 18
      }
    },
    textOur: {
      color: '#FFFFFF',
      fontWeight: 400,
      fontSize: 16,
      textAlign: 'center',
      [theme.breakpoints.down('sm')]: {
        fontSize: 12
      }
    },
    textNotify: {
      color: '#FFFFFF',
      fontWeight: 600,
      fontSize: 20
    },
    textFieldAdress: {
      width: 818,
      height: 68,
      borderRadius: 5,
      background: 'rgba(17, 17, 17, 0.9)',
      boxShadow: 'inset 0px 0px 30px rgba(0, 0, 0, 0.25)',
      border: '1px solid #333333'
    },
    inputLabelProps: {
      color: '#FFFFFF !important',
      fontSize: 14,
      fontWeight: 600,
      transform: 'translate(14px, 27px) scale(1)'
    },
    inputProps: {
      color: '#FFFFFF !important',
      fontWeight: 500,
      fontSize: 14,
      lineHeight: '17px'
    },
    textBody: {
      color: '#FFFFFF',
      fontWeight: 500,
      fontSize: 14,
      marginLeft: 13,
      [theme.breakpoints.down('sm')]: {
        fontSize: 10,
        marginLeft: 8
      }
    },
    marginBody: {
      paddingTop: 100,
      [theme.breakpoints.down('sm')]: {
        paddingTop: 50
      }
    },
    marginContent: {
      marginTop: 10
    },
    listText: {
      animation: `$ease 2s ease-in both`,
      flexWrap: 'nowrap',
      [theme.breakpoints.up('md')]: {
        paddingLeft: '30vw'
      }
    },
    button: {
      width: 180,
      height: 50,
      [theme.breakpoints.down('sm')]: {
        width: 70,
        height: 30
      }
    },
    buttonText: {
      fontSize: 12,
      fontWeight: 600,
      [theme.breakpoints.down('sm')]: {
        fontSize: 8,
        fontWeight: 600
      }
    },
    buttonLayout: {
      marginTop: 50,
      [theme.breakpoints.up('md')]: {
        marginTop: 80
      }
    }
  });

interface IProps extends WithStyles<typeof styles> {}

const TEXT = [
  '  Prove the rights of decentralized assets and allow them to buy and sell freely.',
  'Proof of the assets is performed by Non-fungible token (NFT).',
  'NFTs connect real-world assets with the digital world.',
  'NFTs can trade in open markets that connect buyers and sellers.',
  'In addition, each NFT is unique.',
  'NFTs can be used by decentralized applications (DApps) to allow for the creation and prove your assets.'
];
class LandingPage extends React.Component<IProps> {
  public handleShowDialog = () => {
    this.setState({
      openDialog: true
    });
  };

  public handleClose = () => {
    this.setState({
      openDialog: false
    });
  };

  public onDocumentLoadSuccess = (numPages: any) => {
    this.setState({ numPages });
  };

  public renderText = (text: string, idx: number) => (
    <Grid
      key={text}
      container
      item
      alignItems="center"
      className={this.props.classes.listText}
      style={{ marginTop: 10, animationDelay: `${idx - 0.5}s` }}
    >
      <img src={iconSuccess} width={14} height={10} alt="icon success" />
      <Typography className={this.props.classes.textBody}>{text}</Typography>
    </Grid>
  );
  public render() {
    const { classes } = this.props;
    return (
      <ExternalLayoutComponent>
        <Grid container>
          <Grid justify="center" container item>
            <Typography variant="h1" className={classes.tetxUnited}>
              United Axes
            </Typography>
          </Grid>
          <Grid justify="center" className={classes.marginBody}>
            {TEXT.map((e, index) => this.renderText(e, index))}
          </Grid>
          <Grid
            container
            direction={'row'}
            justify="center"
            className={classes.buttonLayout}
          >
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              // tslint:disable-next-line
              onClick={() =>
                window.open('https://member.unitedaxes.com', '_self')
              }
            >
              <Typography className={classes.buttonText}>Login</Typography>
            </Button>
            <Grid
              style={{
                marginLeft: 35,
                marginRight: 35,
                borderLeft: '1px dashed #00AFC1',
                marginTop: 10,
                marginBottom: 10,
                borderLeftWidth: 1
              }}
            />
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              style={{
                backgroundColor: 'transparent',
                border: '1px solid #00AFC1'
              }}
              title={'Whitepaper'}
              // tslint:disable-next-line
              onClick={() => window.open('/AxesWhitepaper.pdf', '_blank')}
            >
              <Typography
                className={classes.buttonText}
                style={{
                  color: '#00AFC1'
                }}
              >
                Whitepaper
              </Typography>
            </Button>
          </Grid>
        </Grid>
      </ExternalLayoutComponent>
    );
  }
}

export default withStyles(styles)(LandingPage);
