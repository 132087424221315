import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
	props: {
		MuiTypography: {
			align: 'left'
		}
	},
	direction: 'ltr',
	typography: {
		fontFamily: [ 'Montserrat', 'Roboto' ].join(','),
		fontSize: 12,
		h1: {
			fontStyle: 'normal',
			fontSize: 30,
			fontWeight: 500,
			color: '#222222'
		},
		h2: {
			fontSize: 24,
			color: '#222222',
			fontWeight: 600,
			lineHeight: '29px',
			fontStyle: 'normal'
		},
		h3: {
			fontSize: 20,
			color: '#222222',
			fontWeight: 600
		},
		h4: {
			color: '#9BA0A6',
			fontSize: 12,
			fontWeight: 400,
			fontStyle: 'normal',
			lineHeight: '15px'
		},
		subtitle2: {
			fontSize: 14,
			color: '#222222',
			fontWeight: 600,
			fontStyle: 'normal',
			lineHeight: '17px'
		},
		subtitle1: {
			color: '#9BA0A6',
			fontSize: 10,
			fontWeight: 600,
			fontStyle: 'normal',
			textTransform: 'uppercase',
			lineHeight: '12px'
		},
		button: {
			fontWeight: 600,
			fontStyle: 'normal',
			fontSize: 12
		}
	},
	palette: {
		primary: {
			main: '#00AFC1'
		},
		secondary: {
			main: '#36BCA4'
		}
	},

	overrides: {
		MuiFormLabel: {
			root: {
				fontWeight: 500
			}
		},

		MuiOutlinedInput: {
			adornedEnd: {
				paddingRight: 0,
				height: '100%'
			}
		},
		MuiButton: {
			root: {
				borderRadius: 3
			},
			containedPrimary: {
				backgroundColor: '#00AFC1',
				'&:hover': {
					backgroundColor: '#0095A5'
				},
				'&:active': {
					backgroundColor: '#006A75'
				},
				'&:disabled': {
					backgroundColor: '#DCE0E4'
				}
			},

			containedSecondary: {
				backgroundColor: '#36BCA4',
				'&:hover': {
					backgroundColor: '#239D87'
				},
				'&:active': {
					backgroundColor: '#127664'
				},
				'&:disabled': {
					backgroundColor: '#DCE0E4'
				}
			},
			sizeLarge: {
				width: 400,
				height: 50,
				fontSize: 14,
				backgroundColor: '#00AFC1'
			},
			disabled: {},
			contained: {
				boxShadow: 'initial'
			},

			label: {
				color: '#FFFFFF',
				fontFamily: 'Montserrat',
				fontWeight: 600,
				fontSize: 12,
				fontStyle: 'normal'
			},

			sizeSmall: {
				width: 140,
				height: 30
			}
		},
		MuiTextField: {
			root: {
				fontFamily: 'Montserrat',
				fontSize: 14,
				fontStyle: 'normal',
				color: '#9BA0A6',
				fontWeight: 600,
				width: 400,
				height: 50,
				borderRadius: 3,
				background: '#FAFAFB'
			}
		},

		MuiPaper: {
			root: {
				backgroundColor: '#FFFFFF'
			},
			elevation0: {
				boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.05)'
			},
			elevation1: {
				boxShadow: '0px 0px 40px rgba(0, 0, 0, 0.1)'
			},
			rounded: {
				borderRadius: 5
			}
		},
		MuiCard: {
			root: {
				background: 'linear-gradient(150.38deg, #FFE2A8 8.84%, #FFD583 84.66%), #FFD583',
				boxShadow: ' 0px 15px 40px -15px #FFD583',
				borderRadius: '10px'
			}
		},
		MuiSlider: {
			rail: {
				height: 5,
				width: '100%',
				backgroundColor: '#01DEC8',
				boxShadow: '0px 20px 40px rgba(0, 0, 0, 0.2)',
				borderRadius: 10
			},
			track: {
				height: 5,
				width: '100%',
				background: 'linear-gradient(164.09deg, #00E3C8 0.18%, #00AFC1 84.82%), #00AFC1',
				boxShadow: '0px 20px 40px rgba(0, 0, 0, 0.2)',
				borderRadius: 10
			},

			thumb: {
				width: 17,
				height: 17,
				background: 'linear-gradient(146.5deg, #00CEE3 0.72%, #00AFC1 130.79%)',
				boxShadow: '0px 20px 40px rgba(0, 0, 0, 0.2)'
			}
		},
		MuiTooltip: {
			tooltip: {
				height: 35,
				width: 87,
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				backgroundColor: '#FFFFFF',
				boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.1)'
			}
		},
		MuiDialog: {
			paper: {
				margin: 0
			},
			paperWidthSm: {
				maxWidth: 650,
				height: 700
			},
			paperWidthMd: {
				maxWidth: 650,
				height: 550
			},
			paperWidthXs: {
				width: 650,
				maxWidth: '650px !important',
				height: 337
			}
		},
		MuiTableCell: {
			root: {
				borderBottom: '1px dashed #E3E3E3',
				padding: 0,
				height: 50
			}
		},
		MuiSelect: {
			select: {
				'&:focus': {
					backgroundColor: '#FFFFFF'
				}
			},

			selectMenu: {
				textTransform: 'capitalize'
			},
			root: {
				'&$disabled': {
					color: '#000000'
				}
			}
		},
		MuiTablePagination: {
			toolbar: {
				width: '100%'
			},
			spacer: {
				flex: 'none'
			},
			caption: {
				fontWeight: 500,
				fontSize: 12,
				lineHeight: '15px',
				color: '#A1A9B4'
			},
			selectRoot: {
				marginRight: 180
			},
			select: {
				fontWeight: 'bold',
				fontSize: 12,
				lineHeight: '15px',
				color: '#A1A9B4'
			}
		},
		MuiList: {
			padding: {
				paddingTop: 0,
				paddingBottom: 0
			}
		}
	}
});

export default theme;
