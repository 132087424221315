import * as React from 'react';
import { Theme, createStyles, Grid, Typography } from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/styles';
import Background from '../assets/images/background.jpg';
import loading from '../assets/images/loading.gif';
import iconLogo from '../assets/images/iconLogoLogin.svg';

const styles = (theme: Theme) =>
  createStyles({
    backgroundOpacitySignup: {
      display: 'none',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'left'
    },
    backgroundSignup: {
      width: '100%',
      height: '100vh',
      margin: 0,
      backgroundColor: 'rgba(0,0,0,0.5)',
      padding: 60,
      [theme.breakpoints.down('md')]: {
        padding: 30
        // height: '100%'
      },
      position: 'relative',
      alignItems: 'center'
    },
    iconLogo: {
      // position: 'absolute',
      // top: 60,
      // [theme.breakpoints.down('md')]: {
      //   top: 30
      // }
    },
    footer: {
      marginTop: 30
      // position: 'absolute',
      // bottom: 60,
      // [theme.breakpoints.down('md')]: {
      //   bottom: 30
      // }
    },
    unitedAxe: {
      color: '#FFFFFF',
      textTransform: 'uppercase',
      [theme.breakpoints.down('sm')]: {
        fontSize: 10
      }
    },
    loading: {
      display: 'block',
      height: '100vh',
      backgroundColor: '#FFFFFF',
      backgroundImage: `url(${loading})`,
      backgroundSize: 'auto',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center'
    },
    loadingBackground: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }
  });
interface IProps extends WithStyles<typeof styles> {}
class ExternalLayput extends React.Component<IProps> {
  handleLoading = (event: React.SyntheticEvent<HTMLImageElement>) => {
    const page = document.getElementById('page');
    if (page) {
      const bgImg = new Image();
      bgImg.onload = () => {
        page.style.backgroundImage = 'url(' + bgImg.src + ')';
      };
      bgImg.src = Background;
    }
    setTimeout(() => {
      if (page) {
        page.style.display = 'flex';
      }
      const loadingBackground = document.getElementById('loading');
      if (loadingBackground) {
        loadingBackground.style.display = 'none';
      }
    }, 1000);
  };
  public render() {
    const { classes, children } = this.props;
    return (
      <div onLoad={this.handleLoading}>
        <div id="page" className={classes.backgroundOpacitySignup}>
          <Grid container justify="center" className={classes.backgroundSignup}>
            <Grid container justify="center" className={classes.iconLogo}>
              <img src={iconLogo} alt="icon logo" />
            </Grid>
            {children}
            <Grid container className={classes.footer}>
              <Grid container justify="center">
                <Typography variant="subtitle2" className={classes.unitedAxe}>
                  © 2019 - 2020 united axes. All rights reserved.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </div>
        <div id="loading" className={classes.loading} />
      </div>
    );
  }
}

export default withStyles(styles)(ExternalLayput);
